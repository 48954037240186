import Grid from '@mui/material/Grid'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) =>
	![
		'autoplayAlways',
		'hide',
		'horizontalAlign',
		'light',
		'originalHeight',
		'originalWidth',
		'playIconHorizontalAlign',
		'playIconVerticalAlign',
		'playing',
		'startOnEveryInView',
		'stopOnEveryOutView',
		'template',
		'thresholdInView',
		'verticalAlign',
		'additionalContent',
		'subtitleProps',
		'hasVideo',
		'aboveTheFold',
	].includes(prop)

const textAlign = (align) => {
	switch (align) {
		case 'flex-start':
			return 'left'
		case 'flex-end':
			return 'right'
		case 'center':
			return 'center'
	}
}

const OverlayContainer = styled('div')`
	position: absolute;
	z-index: 2;
	padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(2)};
	left: 0;
	right: 0;
	max-width: 100%;
	height: 100%;
	display: flex;
	mix-blend-mode: screen;
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.4) 0%,
		rgba(0, 0, 0, 0) 25%,
		rgba(0, 0, 0, 0) 57%,
		rgba(0, 0, 0, 0.28) 71.5%,
		rgba(0, 0, 0, 0.4) 100%
	);

	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(6)} ${({ theme }) => theme.spacing(2)};
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0.4) 0%,
			rgba(0, 0, 0, 0) 25%,
			rgba(0, 0, 0, 0) 68.23%,
			rgba(0, 0, 0, 0.4) 100%
		);
	}
`
const Root = styled(Grid, { shouldForwardProp })`
	align-items: ${({ verticalAlign }) => verticalAlign};
	justify-content: ${({ horizontalAlign }) => horizontalAlign};
	position: relative;
	${OverlayContainer} {
		text-align: ${({ horizontalAlign }) => textAlign(horizontalAlign)};
		justify-content: ${({ horizontalAlign }) => horizontalAlign};
	}

	&.bottom {
		${OverlayContainer} {
			align-items: flex-end;
		}
	}

	&.top {
		${OverlayContainer} {
			align-items: flex-start;
			bottom: auto;
		}
	}

	&.center {
		${OverlayContainer} {
			align-items: center;
			bottom: auto;
		}
	}
`

export { Root, OverlayContainer }
