import Typography from '@bluheadless/ui/src/atoms/typography'
import BHLink from '@bluheadless/ui/src/molecules/link'
import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import BHButton from '@bluheadless/ui/src/molecules/button'
import { OverlayContainer } from '@bluheadless/ui/src/organisms/hero/hero.styled'
import Hero from '@bluheadless/ui/src/organisms/hero/hero'

const shouldForwardProp = (prop) => {
	return !['hasSiblings', 'aboveTheFold', 'hasVideo', 'colorHex'].includes(prop)
}

const Subtitle = styled(Typography)`
	display: none;
	font-size: ${({ theme }) => theme.typography.pxToRem(10)};
	line-height: 13px;
	font-family: var(--font-base-semibold);
	letter-spacing: 3px;
	writing-mode: vertical-rl;
	position: absolute;
	right: var(--size-24);
	top: var(--size-24);

	${({ theme }) => theme.breakpoints.up('md')} {
		display: block;
	}
`

const Title = styled(Typography)`
	font-size: ${({ theme }) => theme.typography.pxToRem(26)};
	line-height: 40px;
	margin-bottom: var(--size-16);
	font-family: var(--font-base-light);
	text-align: left;

	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(32)};
	}
`

const HeroStyled = styled(Hero)`
	height: 100%;
	display: flex;
	align-items: center;
	position: relative;
	justify-content: ${({ horizontalAlign }) => horizontalAlign};
	min-height: inherit !important;

	> a {
		width: 100%;
		position: relative;

		${({ theme }) => theme.breakpoints.up('md')} {
			height: fit-content;
		}
		> div {
			${({ theme }) => theme.breakpoints.up('md')} {
				height: fit-content;
			}
		}
	}
	&.bottom {
		${({ theme }) => theme.breakpoints.down('md')} {
			align-items: center;
			text-align: left;
		}
	}
	&.top {
		${({ theme }) => theme.breakpoints.down('md')} {
			align-items: center;
			text-align: left;
		}
	}
	${OverlayContainer} {
		left: 0;
		position: absolute;
		top: 0;
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		margin: 0;
		background: transparent;
		padding: 0;
		mix-blend-mode: unset;
	}
`

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	padding: 0;
	max-width: unset;
	height: 100%;
`

const TextContainer = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: var(--size-16);
	backdrop-filter: blur(200px);
	height: calc(100% - 40px);
	margin: 20px;

	${({ theme }) => theme.breakpoints.up('md')} {
		padding: var(--size-32);
		height: calc(100% - 260px);
		margin: 130px;
	}
`

const Description = styled('div')`
	${({ theme }) => theme.typography.subheadline1};
	margin-bottom: var(--size-16);
	text-align: left;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin-bottom: var(--size-32);
	}
`

const CtaContainer = styled(MuiGrid, { shouldForwardProp })`
	flex-direction: column;
	gap: 22px;
	margin-top: 0;
	${({ theme }) => theme.breakpoints.up('md')} {
		flex-direction: row;
		gap: 38px;
	}
	.MuiButton-root.MuiButton-textPrimary {
		font-size: ${({ theme }) => theme.typography.pxToRem(12)};
		width: fit-content;
		display: block;
	}
`

const Link = styled(BHLink)`
	width: auto;
	${({ theme }) => theme.breakpoints.up('md')} {
		width: 100%;
	}
`

const Button = styled(BHButton, { shouldForwardProp })`
	color: ${({ colorHex }) => colorHex};
	border-color: ${({ colorHex }) => colorHex};
	&:hover {
		color: ${({ colorHex }) => colorHex};
		border-color: ${({ colorHex }) => colorHex};
	}
	&.MuiButton-textPrimary {
		font-size: ${({ theme }) => theme.typography.pxToRem(14)};
		margin: 0;
	}
`

export { HeroStyled, Subtitle, TextContainer, Container, Title, Description, CtaContainer, Link, Button }
