import Grid from '@mui/material/Grid'
import {
	Container,
	TextContainer,
	Title,
	Description,
	CtaContainer,
	Link,
	Button,
	HeroStyled,
	Subtitle,
} from '@/components/ui/organisms/bg-text-banner/bg-text-banner.styled'

const BgTextBanner = ({
	aboveTheFold,
	hasVideo,
	title,
	titleProps,
	subtitle,
	subtitleProps,
	content,
	descriptionProps,
	cta,
	ctaProps,
	additionalContent,
	backgroundComponent,
	url,
	onClick,
	...props
}) => {
	const fullUrl = url ?? (cta && cta?.length === 1 ? cta[0].url : undefined)
	const fullOnClick = onClick ?? (cta && cta?.length === 1 ? cta[0].onClick : undefined)

	return (
		<HeroStyled
			overlayComponent={
				<Container>
					{subtitle && (
						<Subtitle variant="subheadline1" {...subtitleProps}>
							{subtitle}
						</Subtitle>
					)}
					<TextContainer>
						{title && <Title {...titleProps}>{title}</Title>}
						{content && (
							<Description variant="subheadline1" {...descriptionProps}>
								{content}
							</Description>
						)}
						{cta && cta?.length > 0 && (
							<CtaContainer
								display="inline-flex"
								justifyContent={props.horizontalAlign}
								hasSiblings={!!content || !!title}
								{...ctaProps}
							>
								{cta.map(({ label, url, variant = 'outlined', ...rest }, key) => (
									<Grid item key={key}>
										<Button href={url} variant={variant} {...rest}>
											{label}
										</Button>
									</Grid>
								))}
							</CtaContainer>
						)}
						{additionalContent}
					</TextContainer>
				</Container>
			}
			backgroundComponent={
				<Link
					href={fullUrl}
					onClick={fullOnClick}
					sx={{
						cursor: fullUrl || fullOnClick ? 'pointer' : 'auto',
						height: aboveTheFold ? '100%' : undefined,
						display: hasVideo && aboveTheFold ? 'flex' : undefined,
					}}
				>
					{backgroundComponent}
				</Link>
			}
			{...props}
		/>
	)
}

export default BgTextBanner
